<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string." + $route.params.type) }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-5">
            <div class="mb-3">{{$t('string.total')}} {{countCollected}} / {{countTotal}}</div>
            <v-row>
              <v-col
                class="pa-1"
                cols="3"
                v-for="(item, i) in items"
                :key="i"
                style="position: relative"
              >
                <div
                  class="dex-container"
                  :class="
                    selectedCollection && item.id == selectedCollection.id
                      ? 'selected-border'
                      : 'not-selected-border'
                  "
                >
                  <v-img
                    :src="item.images.icon.url"
                    width="150"
                    class="menu-item"
                    :class="item.inventory == 0 ? 'lock-image' : ''"
                    @click="
                      () => {
                        selectedCollection = item;
                        collectionDialog = true;
                      }
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
            </v-row>
            <!-- <div class="name-box">
              <div class="text-h4" v-if="selectedCollection">
                {{
                  selectedCollection && selectedCollection.inventory == 0
                    ? "???"
                    : selectedCollection.name
                }}
              </div>
            </div> -->
          </div>
        </v-container>
        <v-dialog v-model="collectionDialog" max-width="300" class="rounded-lg">
          <v-card width="" elevation="1" outlined class="" rounded="lg">
            <div class="d-flex justify-content-between">
              <v-spacer></v-spacer>
              <CloseBtn
                size="30"
                :callback="
                  () => {
                    collectionDialog = false;
                  }
                "
              />
            </div>
            <v-card-text class="mb-3">
              <div class="text-center brown lighten-4 rounded-lg py-3 px-3">
                <v-img
                  v-if="selectedCollection"
                  :src="selectedCollection.images.icon.url"
                  width="150"
                  contain
                  style="margin: auto"
                  :class="
                    selectedCollection.inventory == 0 ? 'lock-image' : null
                  "
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <div class="text-h5" v-if="selectedCollection">
                  {{
                    selectedCollection && selectedCollection.inventory == 0
                      ? "???"
                      : selectedCollection.name
                  }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    collectionDialog: false,
    selectedCollection: null,
    items: [],
    InventoryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    countTotal:null,
    countCollected:null,
  }),
  created() {
    this.InventoryApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory/listByTypeAndCategory";

    this.InventoryApi.callbackReset = () => {
      this.InventoryApi.isLoading = true;
      this.InventoryApi.isError = false;
    };

    this.InventoryApi.callbackError = (e) => {
      this.InventoryApi.isLoading = false;
      this.InventoryApi.isError = true;
      this.InventoryApi.error = e;
    };
    this.InventoryApi.callbackSuccess = (resp) => {
      this.items = resp.Item;
      this.InventoryApi.isLoading = false;
      this.countTotal = this.items.length;
      this.countCollected = this.$_.filter(this.items,(o)=>{
        return o.inventory > 0
      }).length
    };
  },
  mounted() {
    this.InventoryApi.params = {
      type: this.$route.params.type,
      category: "large",
    };
    this.$api.fetch(this.InventoryApi);
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreCollections",
      });
    },
  },
};
</script>

<style scoped>
.name-box {
  margin-top: 20px;
  border: #9f7e57 5px solid;
  border-radius: 5px;
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.dex-container {
  aspect-ratio: 1;
  border-radius: 5px;
  background-color: #9f7e57;
}

.lock-image {
  filter: brightness(0);
  opacity: 0.4;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.selected-border {
  border: #68fffc 5px solid;
}

.not-selected-border {
  border: #d0a36e 5px solid;
}
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>