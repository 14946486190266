var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"app-body"},[_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("string." + _vm.$route.params.type))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('string.total'))+" "+_vm._s(_vm.countCollected)+" / "+_vm._s(_vm.countTotal))]),_c('v-row',_vm._l((_vm.items),function(item,i){return _c('v-col',{key:i,staticClass:"pa-1",staticStyle:{"position":"relative"},attrs:{"cols":"3"}},[_c('div',{staticClass:"dex-container",class:_vm.selectedCollection && item.id == _vm.selectedCollection.id
                    ? 'selected-border'
                    : 'not-selected-border'},[_c('v-img',{staticClass:"menu-item",class:item.inventory == 0 ? 'lock-image' : '',attrs:{"src":item.images.icon.url,"width":"150"},on:{"click":() => {
                      _vm.selectedCollection = item;
                      _vm.collectionDialog = true;
                    }},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)])}),1)],1)]),_c('v-dialog',{staticClass:"rounded-lg",attrs:{"max-width":"300"},model:{value:(_vm.collectionDialog),callback:function ($$v) {_vm.collectionDialog=$$v},expression:"collectionDialog"}},[_c('v-card',{attrs:{"width":"","elevation":"1","outlined":"","rounded":"lg"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"30","callback":() => {
                  _vm.collectionDialog = false;
                }}})],1),_c('v-card-text',{staticClass:"mb-3"},[_c('div',{staticClass:"text-center brown lighten-4 rounded-lg py-3 px-3"},[(_vm.selectedCollection)?_c('v-img',{class:_vm.selectedCollection.inventory == 0 ? 'lock-image' : null,staticStyle:{"margin":"auto"},attrs:{"src":_vm.selectedCollection.images.icon.url,"width":"150","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}):_vm._e(),(_vm.selectedCollection)?_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.selectedCollection && _vm.selectedCollection.inventory == 0 ? "???" : _vm.selectedCollection.name)+" ")]):_vm._e()],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }